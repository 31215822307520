<template>
  <layout-default>
    <v-layout wrap align-center justify-center>
      <v-flex xs12 md6>
        <form>
          <v-card style="max-width: 800px">
            <v-card-title
              class="headline font-weight-regular blue-grey white--text"
              >Create release {{ releaseName }}
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="nameSprint"
                label="Sprint"
                required
                v-validate="'required|integer'"
                :error-messages="errors.collect('nameSprint')"
                data-vv-name="nameSprint"
              ></v-text-field>
              <v-text-field
                v-model="nameNumber"
                label="Number"
                required
                v-validate="'required|integer'"
                :error-messages="errors.collect('nameNumber')"
                data-vv-name="nameNumber"
              ></v-text-field>
              <v-text-field
                v-model="nameSuffix"
                label="Suffix"
                required
                v-validate="'alpha'"
                :error-messages="errors.collect('nameSuffix')"
                data-vv-name="nameSuffix"
              ></v-text-field>
              <v-text-field
                v-model="description"
                label="Description"
                data-vv-name="description"
              ></v-text-field>

              <v-switch
                v-model="settings.showAllTags"
                label="Show all tags"
              ></v-switch>

              <v-autocomplete
                v-for="(image, key) in services"
                :key="key"
                v-model="model[image.name]"
                :items="getOrderedTags(image.serviceTags)"
                item-value="id"
                :label="`${image.helmName} (${image.dockerHubRepoName})`"
                prepend-icon="fa-tag"
                v-validate="'required'"
                :error-messages="errors.collect(image.name)"
                :data-vv-name="image.name"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.name }}
                  ({{ data.item.lastUpdated | moment("DD-MM-YYYY H:mm:ss") }})
                </template>
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }}
                  ({{ data.item.lastUpdated | moment("DD-MM-YYYY H:mm:ss") }})
                </template>
              </v-autocomplete>
            </v-card-text>
            <v-card-actions>
              <v-btn :loading="loading" @click="refreshTagsFromDocker">
                Refresh tags
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="info" :loading="createLoading" @click="create">
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
        <v-alert v-if="lastError" :value="true" color="error" icon="fa-error">{{
          lastError
        }}</v-alert>
      </v-flex>
    </v-layout>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/layouts/Default.vue";
import CREATE_RELEASE from "@/graphql/mutation/CreateReleaseMutation.gql";
import LIST_SERVICES from "@/graphql/query/ServicesList.gql";
import LIST_RELEASES from "@/graphql/query/ReleasesList.gql";
import FORCE_UPDATE_TAGS from "@/graphql/mutation/ForceUpdateTagsFromDocker.gql";
import { logError } from "@/helpers/debug.ts";

export default {
  $_veeValidate: {
    validator: "new",
  },
  name: "addRelease",
  components: {
    LayoutDefault,
  },
  data: () => ({
    nameSprint: null,
    nameSuffix: null,
    nameNumber: null,
    description: null,
    createLoading: false,
    loading: false,
    error: String,
    lastError: null,
    model: {},
    settings: {
      showAllTags: false,
    },
  }),
  computed: {
    tagsById() {
      let all = [];

      this.services.forEach((service) => {
        all = all.concat(service.serviceTags);
      });
      const result = all.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
      }, {});

      return result;
    },
    releaseName() {
      return `${this.nameSprint || ""}-${this.nameNumber || ""}${
        this.nameSuffix ? "-" + this.nameSuffix : ""
      }`;
    },
  },
  methods: {
    getOrderedTags(tags) {
      let result = [...tags];
      if (!this.settings.showAllTags) {
        const regex = /^v([0-9]+)\.([0-9]+)\.([0-9]+)(?:-([0-9A-Za-z-]+(?:\.[0-9A-Za-z-]+)*))?(?:\+[0-9A-Za-z-]+)?$/;
        result = result.filter((tag) => tag.name.match(regex));
      }
      result.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
      return result;
    },
    refreshTagsFromDocker() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: FORCE_UPDATE_TAGS,
          update: () => {
            this.loading = false;
            this.$apollo.queries.services.refetch();
          },
        })
        .catch((error) => {
          this.loading = false;
          this.lastError = error;
        });
    },
    prepareTags() {
      const result = [];
      for (let key in this.model) {
        result.push(this.model[key]);
      }
      return result;
    },
    async create() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      this.createLoading = true;
      this.$apollo
        .mutate({
          mutation: CREATE_RELEASE,
          variables: {
            name: this.releaseName,
            description: this.description,
            tags: this.prepareTags(),
          },
          update: (store, { data: { release } }) => {
            this.createLoading = false;
            let releases = store.readQuery({ query: LIST_RELEASES });
            releases.releases.push(release);
            store.writeQuery({ query: LIST_RELEASES, data: releases });
            this.$router.push({ name: "releasesList" });
            return release;
          },
        })
        .catch((error) => {
          this.createLoading = false;
          this.lastError = error;
        });
    },
    getSelectedTagForService(service) {
      if (!this.model[service.name]) {
        return;
      }
      const tagId = this.model[service.name];

      return this.tagsById[tagId];
    },
  },
  apollo: {
    services: {
      query: LIST_SERVICES,
      loadingKey: "loading",
      error(error) {
        logError(error);
        this.error = error;
      },
      update(data) {
        this.model = {};
        for (let service of data.services) {
          const tags = this.getOrderedTags(service.serviceTags);
          // select latest tags automaticaly
          if (tags.length === 0) {
            continue;
          }

          // handle tag versions in url query params
          const findTagId = () => {
            if (this.$router.currentRoute.query[service.name] === undefined) {
              return tags[0].id;
            }

            const demandedTagVersion = this.$router.currentRoute.query[
              service.name
            ];

            for (let tag of tags) {
              if (tag.name === demandedTagVersion) {
                return tag.id;
              }
            }

            return tags[0].id;
          };

          // set default versions
          this.model[service.name] = findTagId();
        }
        return data.services;
      },
    },
  },
};
</script>
