
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"service"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Service"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"helmName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"bitbucketRepoSlug"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"bitbucketRepoWorkspace"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"dockerHubRepoName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"serviceTags"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastUpdated"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":199}};
    doc.loc.source = {"body":"fragment service on Service{\n    id\n    name\n    helmName\n    bitbucketRepoSlug\n    bitbucketRepoWorkspace\n    dockerHubRepoName\n    serviceTags {\n        id\n        name\n        lastUpdated\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
